
.img {
     width: 100%;
}
.flex {
     display: flex;
}
.flex-row {
     @extend .flex;
     flex-direction: row;
     flex-wrap: wrap;

     & > * {
          margin: auto;
          margin-left: 5px;
     }
}

.flex-row-center {
     @extend .flex-row;
     & > * {
          margin-left: auto;
     }
}
.flex-col {
     @extend .flex;
     flex-direction: column;
     & > * {
          margin: auto;
          margin-left: 5px;
     }
}

.flex-col-center {
     @extend .flex-col;
     & > * {
          margin-left: auto;
     }
}

.m-auto {
     margin: auto;
}
.m-0 {
     margin: 0;
}
.m-1 {
     margin: 1vw;
}

.m-2 {
     margin: 2vw;
}

.p-1 {
     padding: 15px 1vw;
}
.p-2 {
     padding: 30px 2vw;
}

.left {
     align-self: flex-start;
     align-items: flex-start;
}
.right {
     align-self: flex-end;
     align-items: flex-end;
}
.mx-auto {
     margin-left: auto;
     margin-right: auto;
}
.mx-1 {
     margin-left: 1vw;
     margin-right: 1vw;
}

.mx-2 {
     margin-left: 2vw;
     margin-right: 2vw;
}

.my-auto {
     margin-top: auto;
     margin-bottom: auto;
}

.my-1 {
     margin-top: 2vh;
     margin-bottom: 2vh;
}

.my-2 {
     margin-top: 4vh;
     margin-bottom: 4vh;
}

.mt-1 {
     margin-top: 2vh;
}

.mt-2 {
     margin-top: 4vh;
}

.mb-1 {
     margin-bottom: 2vh;
}

.mb-2 {
     margin-bottom: 4vh;
}

.ml-0 {
     margin-left: 0;
}

.ml-1 {
     margin-left: 1vw;
}

.ml-2 {
     margin-left: 2vw;
}

.mr-0 {
     margin-right: 0;
}

.mr-1 {
     margin-right: 1vw !important;
}

.mr-2 {
     margin-right: 2vw;
}
.off {
     display: none !important;
}

/**
Conversion standards:
    5px -> 0.3256vw;
    10px -> 0.6511vw;
    15px -> 0.977vw;
    30px -> 1.9533vw;


*/


:root {
     --ag-background-color: #FFF
}

.ag-theme-alpine .ag-row:hover {
     background-color: rgba(234,234,239,0.4) !important;
}
.ag-theme-alpine .ag-row-odd {
     background-color: rgba(234,234,239,0.2) !important;

}
.ag-theme-alpine .ag-header {
     border-bottom: none;
     background-color: #F1F1F6;
     border: 1px solid #B7B9C3;
}

.ag-header-icon {
     color: $gray_8;
     opacity: 1 !important;
}

.ag-icon-menu:before {
     content: "\f112" !important;
  }