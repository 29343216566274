$vertial-navigation-full-width: 300px;
$vertial-navigation-shrinked-width: 60px;
.navigation-background {
     float: left;
     height: 100%;
     border: 1px solid transparent;
     width: $vertial-navigation-full-width;
     box-sizing: border-box;
     padding: 0 150px;
     margin: 0;

     transition: $transition-all;
     @media only screen and (max-width: 768px) {
          width: 0;
          padding: 0;
          display: none;
     }

     @media only screen and (min-width: 769px) {
          &.short-view {
               width: 60px;
               padding: 0;
          }
     }
}
.short-view {
     .main-menu-text, .sub-menu-text, .main-menu-drop-icon {
          transition:   visible 0.2s ease-in-out 2s; 
     }
}
.vertical-navigation {
     @include show;
     @extend .flex-col-center;
     width: $vertial-navigation-full-width;

     box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
     min-width: $vertial-navigation-full-width;
     background-color: #0e416c;
     color: #fff;
     fill :#fff;
     top: 0; 
     overflow: hidden;
     user-select: none;
     z-index: 1;
     .nav-show {
          min-height: calc(100vh - 56px);
     }
     
     .main-menu-text, .sub-menu-text, .main-menu-drop-icon {
          white-space: nowrap;
     }

     .main-menu-text {
          .MuiTypography-body1 {
               font-size: 14px;
               font-weight: 500;
          }
     }

     .sub-menu-text {
          .MuiTypography-body1 {
               font-size: 14px;
               font-weight: 400;
               color: #fff;

          }
     }

     @media only screen and (max-width: 768px) {
          height: 0;
          position: fixed;
          top: 56px;
          &.nav-show {
               height: 100vh;
          }
     }
     .menu-icon {
          @extend .flex-row-center;
          @extend .btn-text-primary;
          @include show;
          cursor: pointer;
          width: 50px;
          height: 50px;
          position: absolute;
          bottom: 0;
          width: inherit;
          display: block;
          background-color: rgba(0,0,0,0.1);
          transition: none;
          
          @media only screen and (max-width: 768px) {
               display: none;
          }
          
          button {
               float: right;
          }
          .icon {
               transform: rotate(180deg);
          }
     }
     
     .search-bar {
          margin: 0 15px 15px 15px;
          box-sizing: border-box;
          width: calc(100% - 30px);
          background-color: rgba( 250, 250, 250, 0.3);
          @media only screen and (min-width: 769px) {
               display: none;
          }
     }
     .user {
          @extend .flex-col-center;
          margin:15px;
          text-decoration: none;
          color: inherit;
          border-radius: 10px;
          .photo-block {
               @extend .flex-row-center;
               width: 100%;
               .photo {
                    box-sizing: border-box;
                    box-shadow: 0 4px 4px rgba(10, 16, 20, 0.24), 0 0 4px rgba(10, 16, 20, 0.12);
                    margin-left: 0;
                    width: 60px;
                    border-radius: 50%;
                    height: 60px;
                    background-color: #fff;
               }
          }

          .name {
              
               @include show;
               font-weight: 500;
               font-size: 18px;
               line-height: 21px;
               text-align: left;
               max-width: 100%;
               margin-left: 0;
               white-space: normal;
               margin-top: 16px;
               margin-bottom: 8px;
          }
          .sector {

               @include show;
               @extend .text-hide-on-scroll;
               font-weight: 400;
               font-size: 14px;
               line-height: 16px;
               margin-left: 0;
               color: #fff
          }
     }

     .navigation-panel {
          border-top: 0.5px solid rgba(255,255,255,0.3);
          width: 100%;
          margin-top: 0;
          overflow-y: auto;
          max-height: calc(100vh - 64px);
          .application-title {
               overflow-x: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
          }

          .title {
               @extend .m-1;
               @extend .text-hide-on-scroll;
               @include show;
          }
          .navigation-tab, .navigation-tab:hover,
               .navigation-tab:visited, .navigation-tab:active {
                    color: inherit !important;
                    text-decoration: none !important;
                    display: flex;
                    &.active {
                         background-color: #217CBB;
                         
                    }
               }

               .navigation-tab {
                    height: 48px;
                    padding: 0;
               }

          .navigation-list {
               list-style: none;
               padding-left: 0;

               
               .navigation-tab {
                    height: 48px;
                    padding: 16px;
                    display: flex;
                    flex-direction: row;
                    &>*{
                         margin-top: auto;
                         margin-bottom: auto;
                    }
                    
                    box-sizing: border-box;
                    color: #FFF;
                    text-decoration: none;
                    margin-right: 16px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    margin-bottom: 0;
                    transition: all 0.2s ease-in-out 0.5s , color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
                    .icon {
                         font-size: 2rem;
                         margin-right: 10px;
                         font-weight: 500;
                    }
                    .page-icon {
                         width: 40px;
                    }

                    &:hover,
                    &.active {
                         background-color: rgba(18,18,18, 0.52);
                    
                    }
                    .page-name {
                         width: auto;
                         @extend .text-hide-on-scroll;
                         transition: all 0.2s ease-in-out 0.5s , color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;     
                    }
               }
          }
     }
}

@media only screen and (min-width: 769px) {
     .vertical-navigation.short-view {
          min-width: $vertial-navigation-shrinked-width;
          width: $vertial-navigation-shrinked-width;

          .search-bar {
               display: none;
          }
          .dropdown-white{
               display: none;
          }
          .main-menu-text, .sub-menu-text, .main-menu-drop-icon {
               white-space: nowrap;
          }
          .user {
               border-radius: 0;
               box-sizing: border-box;
               height: 60px;
               margin: 0;
               margin-bottom: 105px;
               padding: 0;

               & > * {
                    margin: auto;
               }
               display: flex;
               flex-direction: row;
               flex-wrap: wrap;
               .photo-block {
                   & > * {
                         margin: auto;
                    }
                   display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: auto;
                    .photo {
                         border-radius: 50%;
                         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                         box-sizing: border-box;
                         height: 40px;
                         transition: $transition-all;
                         width: 40px;
                    }
               }

               .name {
                    @include hide;
               }
               .sector {
                    @include hide;
               }
          }

          .navigation-panel {
               border-top: none;
               overflow-x: hidden;
               .title,.application-title {
                    display: none;
                    visibility: hidden;
               }
               .main-menu-text, .sub-menu-text, .main-menu-drop-icon {
                    display: none;
               }
               .navigation-list {
                    list-style: none;
                    padding-left: 0;
                    .navigation-tab {
                       margin-right: 0;
                       & > * {
                              margin: auto;
                              margin-left: 5px;
                         }
                         display: flex;
                         flex-direction: row;
                         flex-wrap: wrap;
                         height: auto;
                         padding: 15px 5px;
                         .page-name {
                              @include hide;
                              margin: 0;
                              height: 0;
                         }
                    }
               }
          }
     }
}
.disabled-nav-link {
     background-color: #555 !important;
}

.dropdown {
     position: relative;
     display: flex;
     .MuiOutlinedInput-notchedOutline{
     color:#000;
     border-color: #000 !important;
     }

     .MuiSelect-selectMenu {
     color:#000 !important;
     border-color: #000 !important;
     }
     .MuiSelect-icon{
           color:#000 !important;
     }
     .MuiInputLabel-animated{
          color: #000 !important;
          font-size: 16px;
          padding-bottom: 6px;
          font-weight: 500;
     }
     .MuiFormControl-root{
          width: auto !important;
     }
     optgroup {
          background-color: #000;
          color: black;
      }
      .MuiNativeSelect-icon {
          color: #000;
      }
     width: 100% !important;
}

.makeStyles-content-102 {
    width: 100%;
    border-top: 0.5px solid rgba(0, 0, 0, 0.3) !important;
}


.MuiNativeSelect-select.MuiNativeSelect-select {
     height: auto;
     padding-right: 32px !important;
}