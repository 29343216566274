button.legacy-button {
     display: inline-block;
     font-weight: 600;
     text-align: center;
     vertical-align: middle;
     cursor: pointer;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     background-color: transparent;
     border: 1px solid transparent;
     padding: 0.1875rem 1rem;
     font-size: 0.875rem;
     line-height: 1.75;
     border-radius: 0.125rem;
     transition: $transition-all;
     white-space: nowrap;
     -webkit-appearance: button;
     text-transform: none;
     overflow: visible;
     color: #fff;
     fill: #fff;
     background-color: #005f9e;
     border-color: #005f9e;
     &:hover:not(:disabled) {
          color: #fff;
          background-color: #007ed1;
          border-color: #007ed1;
     }
     &:disabled {
          cursor: not-allowed;
     }
     .icon {
          margin-right: 5px;
     }
}
.btn-text-primary.legacy-button  {
     color: #005f9e;
     fill: #005f9e;
     border-color: transparent;
     background-color: transparent;
     &:hover {
          color: #007ed1;
          background-color: rgba(0, 95, 158, 0.2);
     }
}

.unsafe-action {
     color: #fff;
     background-color: #c81922;
     border-color: #c81922;
     &:hover {
          color: #fff;
          background-color: #b70811;
          border-color: #b70811;
     }
}

.delete {
     @extend .unsafe-action;
}
// Container which holds all type of action (buttons)
.actions {
     @extend .flex-row;
     justify-content: flex-end;
     button {
          @extend .mr-0;
     }
}


.link-button {
     color: #fff !important;
      background-color: #00437B;
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      padding: 6px 16px;
      font-size: 0.875rem;
      min-width: 64px;
      box-sizing: border-box;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 500;
      line-height: 1.75;
      border-radius: 8px;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      margin: auto !important;
}