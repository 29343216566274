$body-background-color : #F3F3F7;
// trimble-brand-colors
$trimble-blue-dark : #002D5B;
$trimble-blue : #00437B;
$trimble-blue-mid : #005F9E;
$trimble-blue-light : #009AD9;
$trimble-yellow : #FFBE00;
$trimble-green : #4D6F33;
$trimble-red : #A51D25;
$trimble-gray : #363545;

// theme-colors 
$primary : #005F9E;
$secondary : #8C8B96;
$tertiary : #D0D0D7;
$dark : #363545;
$success : #5E9331;
$warning : #FFA500;
$danger : #C81922;

// ui-colors
$main-background : #FFFFFF;
$panel-background : #FFFFFF;
$active : #0C77BE;
$hover : #EAEAEF;
$selected : #E5F0F8;

// Gray Colors
$gray-50 : #F9F9Fb;
$gray-light : #F3F3F7;
$gray-0 : #EAEAEF;
$gray-1 : #E2E2E7;
$gray-2 : #D0D0D7;
$gray-3 : #BFBFC6;
$gray-4 : #AEAEB6;
$gray-5 : #9D9DA6;
$gray-6 : #8C8B96;
$gray-7 : #7B7A86;
$gray-8 : #6A6976;
$gray-9 : #595868;
$gray-dark-1 : #262533;
$gray-dark-2 : #1B1A26;
$gray-dark-3 : #0B0A12;

// additional Button colors
$action-hover :#007ed1;

$white: #fff;