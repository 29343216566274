.single-select-dropdown {
     position: relative;
     cursor: pointer;
     .selected {
          display: block;
          appearance: none;
          outline: none;
          width: 100%;
          padding: 0.1875rem 0.625rem;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 2;
          min-height: 40px;
          color: #363545;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #e2e2e7;
          border-radius: 0.125rem;
          overflow: visible;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin: 0;
          font-family: inherit;
          box-sizing: border-box;
          padding-top: 7px;
     }
     &.valid {
          .value-container {
               border-bottom: 2px solid;
               border-bottom-width: 0.125rem;
               border-bottom-color: #4d6f33;
          }
     }
     &.error {
          .value-container {
               border-bottom: 2px solid;
               border-bottom-width: 0.125rem;
               border-bottom-color: #c81922;
          }
     }
     .value-container {
          position: relative;
          border-bottom: 2px solid;
          border-bottom-width: 0.125rem;
          border-bottom-color: #0c77be;
          .icon {
               position: absolute;
               right: 10px;
               top: 13px;
               transform: rotate(90deg);
          }
     }
     .options {
          background-color: #fff;
          box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
          position: absolute;
          top: 0;
          width: 100%;
          max-height: 170px;
          overflow: auto;
          transition: $transition-all;
          .option {
               padding: 0.655vw 5px;
               &:hover {
                    background-color: #ccc;
               }
          }
          &.show {
               min-height: 40px;
               padding: 5px;
               z-index: 1;
               border-bottom: 2px solid;
               border-bottom-width: 0.125rem;
               border-bottom-color: #0c77be;
          }
          &.hide {
               height: 0;
               overflow: hidden;
          }
     }
     .helper-block {
          @extend .flex-col;
          position: relative;
          & > * {
               @extend .right;
               margin-right: 10px;
               position: absolute;
               margin-top: -10px;
               width: fit-content;
               right: 10px;
               position: absolute;
               margin-top: -25px;
               z-index: -1;
               text-align: right;
               transition: $transition-all;
          }
          font-size: 0.8125rem;
          .error {
               color: #c81922;
               margin-top: 0;
               z-index: 0;
          }
          .form-message {
               color: #0c77be;
          }
          min-height: 20px;
     }
     .error {
          color: #c81922;
          font-size: 0.8125rem;
     }
     .form-message {
          color: #0c77be;
          font-size: 0.8125rem;
     }
}
