.nav-tabs {
     border-bottom: 1px solid #e2e2e7;
     display: flex;
     flex-wrap: wrap;
     padding-left: 0;
     @extend .my-1;
     margin-top: 0;

     .nav-item {
          text-align: center;
          text-transform: capitalize;
          text-decoration: none;
          font-weight: 600;
          font-size: 0.875rem;
          color: #0c77be;
          padding: 0.625rem 2rem;
          border: 1px solid transparent;
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem;
          &.active {
               background-color: transparent;
               border-color: transparent transparent transparent;
               border-bottom: 0.1875rem solid #0c77be;
               &:hover {
                    border-bottom: 0.1875rem solid #0c77be;
               }
          }
          &:hover {
               border-color: transparent transparent #e2e2e7;
               background-color: #e5f0f8;
          }
     }
}
