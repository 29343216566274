.cards {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
}
.card {
    @extend .mt-2;
    @extend .mb-1;
    @extend .p-1;
    @extend .m-auto;
    cursor: pointer;
    width: 300px;
    height: 200px;
    position: relative;
    margin: 15px;
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 2px;
    transition: box-shadow 0.5s;
    padding-bottom: 48px;

    &:hover {
         cursor: pointer;
         box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
         .actions {
              background-color: rgba(25, 118, 210, 0.1);
         }
    }
    .image-block {
         @extend .flex-row-center;
         width: 50px;
         height: 50px;
         position: absolute;
         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.37);
         background-color: $iconic-image-background-color;
         border-radius: 50%;
         top: -25px;
         left: 16px;
         fill: #fff;
         cursor: pointer;
    }
    .description {
         text-align: left;
         max-height: 87px;
         text-overflow: ellipsis;
         overflow: hidden;
         padding: 0 8px;
         margin-top: 0px;
    }
   
    .actions {
         position: absolute;
         padding: 0 10px;
         color: #1976d2;
         width: 100%;
         min-height: 45px;
         left: 0;
         bottom: 0;
         box-sizing: border-box;
         border-top: 0.5px solid #dbdbdb;
    }
    .name {
         padding: 8px;
         padding: 0 8px;
         margin-top: 24px;
         text-transform: capitalize;
         text-overflow: ellipsis;
         max-width: 300px;
         overflow-x: hidden;
    }
}
