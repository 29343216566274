@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "../../themes/skins/default/colors";

.table {
  overflow: hidden;
  @include ag-theme-alpine(
    (
      background-color: $white,
      header-foreground-color: $trimble_gray,
      odd-row-background-color: $white,
      header-background-color: $gray_0,
      foreground-color: $trimble_gray,
      alpine-active-color: rgba(0, 95, 158, 0.2),
      borders: true,
      row-border-color: $gray_0,
      range-selection-border-color:
        ag-derived(alpine-active-color, $opacity: 0.72),
    )
  );
  .ag-root-wrapper {
    border: transparent;
  }
  .ag-header {
    border-bottom-width: 0;
    background-color: #F1F1F6; //#B7B9C3;
    border: 1px solid #B7B9C3;
  }

  .ag-header-cell-text {
    color: #464B52;
    font-size: 14px;
    font-weight: 600;
  }
  .ag-row {
    border: 1px solid #B7B9C3;
    background-color: #fff;
  }
  .ag-center-cols-viewport {
    border: 1px solid #B7B9C3;
    border-top:0;
  }
  .ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container {
    min-height: 48px;
  }

  .header-cell-text {
    font-size: 14px;
    font-weight: 600;
    color: $trimble_gray;
  }
  
  .ag-icon-asc:before {
    content: "\f12b";
  }

  .ag-icon-desc:before {
    content: "\f128";
  }

  .ag-icon-menu:before {
    content: "\f112";
 }

  .ag-header-icon {
    color: $gray_8;
    opacity: 1 !important;
  }
  
  .ag-theme-alpine .ag-row-odd {
    background-color: transparent !important;
  }
}
.ag-cell {
  display: flex;

  .ag-react-container {
    flex-direction: column;
  }
}
.grid-title {
  
  color: $trimble-blue-mid !important;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
  &>*{
    
  color: $trimble-blue-mid !important;
  }
}


.ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container {
  min-height: 48px !important;
}

.ag-grid-header-cell {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ag-body-horizontal-scroll {
  display: none;
}

.ag-row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}