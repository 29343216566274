.team-api-list {
     @extend .flex-row;
     flex-wrap: wrap;
     min-width: 300px;
     min-height: 300px;
     justify-content: center;
     &>.proxy-loader {
          margin: auto;
     }
}
.proxy-list {
     position: relative;
     h1 {
          button {
               position: absolute;
               right: 30px;
          }
     }
}
.shared-api-list {
     @extend .flex-row;
     flex-wrap: wrap;
     justify-content: center;
}
.sharedproxy-list {
     position: relative;
     h1 {
          button {
               position: absolute;
               right: 30px;
          }
     }
}
