.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    flex-shrink: 0;
    max-width: 100%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  & > * {
    flex-shrink: 0;
    max-width: 100%;
  }
}

$devices: "xs", "sm", "md", "lg", "xl";
$width: (
  "xs": 576px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200,
);

@each $device in $devices {
  @for $index from 1 through 12 {
    @media only screen and (min-width: map-get($width , $device )) {
      .col-#{$device}-#{$index} {
        width: $index * 8.33333333%;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .col-xs-12 {
    width: 100%;
  }
}
