.product-list {
    &__wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - 24px);
    }

    &__content {
        position: relative;
       
        height: calc(100% - 72px);
    }

    &__card {
        $self: &;

        &#{$self}--add {
            width: 296px;
            height: 208px;
        }

        &-action {
            width: 100%;
            height: 100%;

            .MuiButton-label {
                display: block;
            }
        }
    }
}
