.throttling-config {
    padding: 10px;
    margin: 10px;
    background: rgba(238, 238, 238, 0.15);
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    color: #333333;

    .throttling-save {
        position: absolute;
        right: 0;
        top:8px;
        margin-right: 17px;
        width: 60px;
        height: 30px;
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
        transition: box-shadow 0.5s;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        text-transform: capitalize;
        transition: $transition-all;
        user-select: none;
        
    }

    .throttling-block {
        padding: 15px;
        margin-top: 10px;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        border-radius: 8px;
        position: relative;
        background: rgba(238, 238, 238, 0.35);
    }

    .throttling-textfield {
        width: 150px;
    }

    .throttling-dropdown {
        margin-left: 10px;
        height: 40px;
        width: 150px;
    }

    .throttling-switch {
        margin-left: 10px;
    }

    .throttling-text {
        height: inherit;
        display: inline-flex;
        margin: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
}