$spacing: 8px;
@for $index from 0 through 5 {
  .m-#{$index} {
    margin: $index * $spacing !important;
  }
  .p-#{$index} {
    padding: $index * $spacing !important;
  }

  .m-x#{$index} {
    margin-left: $index * $spacing !important;
    margin-right: $index * $spacing !important;
  }
  .m-y#{$index} {
    margin-top: $index * $spacing !important;
    margin-bottom: $index * $spacing !important;
  }

  .m-t#{$index} {
    margin-top: $index * $spacing !important;
  }
  .m-b#{$index} {
    margin-bottom: $index * $spacing !important;
  }
  .m-l#{$index} {
    margin-left: $index * $spacing !important;
  }
  .m-r#{$index} {
    margin-right: $index * $spacing !important;
  }

  .p-t#{$index} {
    padding-top: $index * $spacing !important;
  }
  .p-b#{$index} {
    padding-bottom: $index * $spacing !important;
  }
  .p-l#{$index} {
    padding-left: $index * $spacing !important;
  }
  .p-r#{$index} {
    padding-right: $index * $spacing !important;
  }

  .p-x#{$index} {
    padding-left: $index * $spacing !important;
    padding-right: $index * $spacing !important;
  }
  .p-y#{$index} {
    padding-top: $index * $spacing !important;
    padding-bottom: $index * $spacing !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}
