.profile {
     .user {
          @extend .flex-col-center;
          @extend .m-1;
          text-decoration: none;
          color: inherit;
          border-radius: 10px;
          padding: 15px 1vw;

          &__actions {
               padding-top: 32px;
          }

          .photo-block {
               @extend .flex-row-center;
               &:hover {
                    .photo-edit {
                         visibility: visible;
                    }
               }
               .photo {
                    width: 150px;
                    border-radius: 50%;
                    height: 150px;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
               }

               .photo-edit {
                    visibility: hidden;
                    width: 150px;
                    border-radius: 50%;
                    display: inline-flex;
                    height: 150px;
                    box-sizing: border-box;
                    background-color: rgba(255, 255, 255, 0.25);

                    background-color: rgba(0, 0, 0, 0.25);
                    cursor: pointer;
                    position: absolute;
                    > * {
                         @extend .m-auto;
                         font-size: 3em;
                         color: #ddd;
                    }
                    #photo-receiver {
                         display: none;
                    }
               }
          }

          .name {
               @extend .my-1;
               font-weight: 500;
               font-size: 18px;
               line-height: 21px;
               text-align: center;
          }
     }
     .user-details {
          border-top: 1px solid #015796;
          border-bottom: 1px solid #015796;
          width: 50%;
          margin: auto;
          @media only screen and (max-width: 1200px) {
               width: 80%;
          }
          .row {
               padding: 15px 1vw;
               & > * {
                    display: inline-flex;
                    width: 50%;
               }
          }
     }
}
