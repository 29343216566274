header {
     @extend .flex-row;
     background-color: $header-background-color;
     color: $header-text-color;
     height: 56px;
     width: calc( 100vw - 300px );
     display: flex;
     flex-direction: row;
     position: fixed;
     transition: all 0.2s ease-in-out;
     
     box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
     .application-branding {
          @extend .flex-row;
          padding: 1vw 0;
          font-weight: 600;
          margin-left: 15px;
          font-weight: 500;
          font-size: 20px;
     }
     .icon-company-full-logo {
          font-size: 1.6rem;
     }
     

     .mobile-navigation-toggler {
          padding: 15px 18px;
          border-radius: 50%;
          &:hover {
               cursor: pointer;
               background-color: rgba(255, 255, 255, 0.2);
          }
     }
}
