article.right-slider {
     position: fixed;
     width: 30%;
     top: 167px;
     padding: 15px;
     height: 76vh;
     height: calc(100vh - 17px);
     right: 0;
     background-color: #fff;
     box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
     transition: $transition-all;
     margin-right: -30%;
     box-sizing: border-box;
     &.show {
          margin-right: 0;
     }
     h1 {
          text-transform: capitalize;
          .icon {
               position: absolute;
               right: 15px;
               cursor: pointer;
               color: $header-background-color;
          }
     }
}

@media only screen and (max-width: 990px) {
     article.right-slider {
          width: 50%;
          margin-right: -50%;
     }
}

@media only screen and (max-width: 768px) {
     article.right-slider {
          width: 100%;
          margin-right: -100%;
     }
}
