$transition-time: 0.2s;
$transition-time-short: 0.1s;
$transition-time-long: 0.3s;
$transition-function: ease-in-out;
$transition-delay: 0.1s;

$transition-all: all $transition-time $transition-function;

@mixin hide {
     visibility: hidden;
     width: 0;
     text-overflow: clip;
}

@mixin show {
     visibility: visible;
     transition: all $transition-time $transition-function, 
     width $transition-time $transition-function,  visibility $transition-time $transition-function $transition-delay ;
}

.text-hide-on-scroll {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: clip;
}
