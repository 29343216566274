

 .MuiChip-sizeSmall {
     height: 18px !important;
 }

 .MuiChip-labelSmall {
     padding-left: 6px !important;
     padding-right: 6px !important;
 }


 .MuiChip-root {

     font-size: 0.7rem !important;
 }