.proxy-basics {
     .introduction {
          @extend .flex-row;
          .icon {
               font-size: 5rem;
               font-weight: 400;
               color: #0c77be;
               border: 3px solid #0c77be;
               width: auto;
               padding: 10px 7px 10px 16px;
               border-radius: 50%;
               margin-right: 20px;
          }
          .identifier {
               @extend .flex-col;
          }
     }
     .description {
          @extend .mt-2;
     }
 
}

.proxy-summary {
     margin: 0 24px
}

    
svg.proxy-icon {
     font-size: 6rem;
     border: 3px solid #0c77be;
     border-radius: 50%;
     fill: #0c77be;
}

.proxy-summary-header{
     width: max-content;
     width: fit-content;
}