.text-input,
.text-area {
     textarea {
          width: 100% !important;
          min-height: 80px;
     }
     input[type="text"],
     textarea,
     input[type="number"] {
          display: block;
          appearance: none;
          outline: none;
          width: 100%;
          padding: 0.1875rem 0.625rem;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 2;
          color: #363545;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #e2e2e7;
          border-radius: 0.125rem;
          overflow: visible;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin: 0;
          font-family: inherit;
          box-sizing: border-box;

          &:focus {
               color: #363545;
               background-color: #fff;
               border-color: #e2e2e7;
               border-bottom-color: #0c77be;
               border-bottom-width: 0.125rem;
               & + .helper-block {
                    & > .form-message {
                         margin-top: 0;
                         z-index: 0;
                    }
               }
          }

          &:disabled {
               cursor: not-allowed;
          }
     }

     &.error {
          input[type="text"],
          textarea,
          input[type="number"] {
               border-bottom-color: #c81922;
               border-bottom-width: 0.125rem;
               &:focus {
                    border-bottom-width: 0.125rem;
                    border-bottom-color: #0c77be;
               }
          }
     }
     &.valid {
          input[type="text"],
          textarea,
          input[type="number"] {
               border-bottom-width: 0.125rem;
               border-bottom-color: #4d6f33;
          }
     }
     .helper-block {
          @extend .flex-col;
          position: relative;
          & > * {
               @extend .right;
               margin-right: 10px;
               position: absolute;
               margin-top: -10px;
               width: fit-content;
               right: 10px;
               position: absolute;
               margin-top: -25px;
               z-index: -1;
               text-align: right;
               transition: $transition-all;
          }
          font-size: 0.8125rem;
          .error {
               color: #c81922;
               margin-top: 0;
               z-index: 0;
          }
          .form-message {
               color: #0c77be;
          }
          min-height: 20px;
     }
}

.text-area {
     width: 100%;
}
