@media only screen and (min-width: 769px) {
     .mobile-view {
          display: none !important;
     }
}
@media only screen and (max-width: 768px) {
     .desktop-view {
          display: none !important;
     }
}

