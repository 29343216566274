.home-section {
     transition: $transition-all;
     min-height: calc(100vh - 104px);
     max-height: calc(100vh - 104px);
     overflow-y: auto;
     box-sizing: border-box;
     padding: 15px;
     margin-left: 0;
     background-color: $body-background-color;

}

.helper-image {
     width: 100%;
     height: auto;
}

.helper {
     p {
          text-align: justify;
     }
}

.home-link {
     background-color: inherit !important;
}

.click-hider {
     position: fixed;
     top:0;
     left: 0;
     width: 100vw;
     z-index: 1200;
}
.hide1 {
     height: 60px;
}
.hide2 {
     width: 300px;
     height: 200px;
}

main {
     display: flex;
     flex-direction: row;
     transition: $transition-all;
     .main-content {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          transition: $transition-all;
     }
}


.ModusFormTextInput--root {
     margin-top: 10px;
    & .MuiOutlinedInput-notchedOutline {
          border: 1px solid #e0e1e9;
          border-bottom: 2px solid #6A6E79;
    }
  & .Mui-disabled {
    background-color: #e0e1e9;
    color: #252A2E;
    &+.MuiOutlinedInput-notchedOutline {
          border: none;
          border-bottom: 2px solid #6A6E79;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
    }
  }    
}

.required {
     color: #AB1F26;
}

.notification-card {
     border: 1px solid rgb(0, 99, 163);
     border-radius: 4px;
     width: 100%;
     padding: 15px;
     margin: 15px;
     display: flex;
     column-gap: 15px;

     .badge {
          padding: 2px 8px;
          border-radius: 18px;
          margin-top: 15px;
     }
     .warning {
          background-color: #FBAD26;
          color: #000;
     }
     .success {
          background-color: #006638;
          color: #fff;
     }
     .error {
          background-color: #DA212C;
          color: #fff;
     }
}

.notification-accordion {
     [class*="MuiAccordionDetails-root"] {
          max-height: calc(65vh - 232px);
          overflow-y: auto;
     }
     [class*="MuiAccordionSummary-root"] {
          border-left: 4px solid transparent;
     }
     .Mui-expanded[class*="MuiAccordionSummary-root"] {
          background-color: #DCEDF9;
          border-left: 4px solid #0063A3;
     }
     [class*="MuiIconButton-label"] {
          svg {
               color: #0063A3 !important;
               fill : #0063A3 !important;
          }
     } 
     &.Mui-expanded {
          [class*="MuiAccordionDetails-root"] {
               border-left: 4px solid #0063A3;
          }
     }
}


.email-error {
     color: #AB1F26;
     font-size: 12px;
     margin-top: 5px;
     text-align: right;
}

.email-error-field{
     fieldset {
          border-bottom: 2px solid #AB1F26 !important;
     }
}

.action-loader {
     margin-right: 10px !important;
     width: 15px !important;
     height: 15px !important;
     color: rgb(255, 255, 255) !important;
}