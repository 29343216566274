h1,
h2,
h3,
h4,
h5,
h6 {
     margin-bottom: 8px;
     margin-top: 0px;
}

h6.MuiTypography-h6 {
     margin: 15px;
}

.capitalize {
     text-transform: capitalize;
}
.editOnHover {
     display: none !important;
}
button:hover  .editOnHover {
     display: block !important;
}

.link {
     color: #00437B;
     text-decoration: none;
     &:active, &:visited, &:focus {
          color: #00437B;
     }
     &:focus, &:active {
          text-decoration: underline;
     }
}