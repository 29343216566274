.message {
     position: relative;
     padding: 0.75rem 1.25rem;
     margin-bottom: 1rem;
     border: 1px solid transparent;
     border-radius: 0.25rem;
     text-align: center;
     margin: auto;
     &.info {
          color: #004085;
          background-color: #fff;
          border-color: #b8daff;
     }
     &.error {
          color: #c81922;
          background-color: #fff;
          border-color: #c81922;
     }
}

.no-overflow {
     text-overflow: ellipsis;
     overflow: hidden;
     text-align: justify;
     max-width: calc( 100vw - 56vw);
     word-break: break-all;
}
