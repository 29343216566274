html,
body {
     height: 100vh;
}
html,
body,
#root {
     margin: 0;
     padding: 0;
     background-color: #F3F3F7;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-family: 'Open Sans', sans-serif !important;
}
div, span, p {
     font-family: 'Open Sans', sans-serif !important;
}

.main-content {
     min-height: calc(100vh - 56px);
}
.home-section {
     min-height: calc(100vh - 104px);
}

code, .use-code-font {
     font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace!important;
     line-break: anywhere;
}

::-webkit-scrollbar {
     width: 8px;
     height: 8px;
     position: relative;
     z-index: 0;
}

::-webkit-scrollbar-track-piece {
     background-color: transparent;
}

::-webkit-scrollbar-thumb {
     background-color: rgba(0, 0, 0, 0.32);
     border-radius: 3px;
     background-clip: padding-box;
     padding: 100px 0 0;
     box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-thumb:hover {
     background-color: rgba(0, 0, 0, 0.51);
}

::-webkit-scrollbar-corner {
     background-color: transparent;
}

@import "./themes/index.scss";





.ot-close-icon {
     display: none;
}

.ag-filter-select.ag-select {
     display: none !important;
}

.authz {
     margin-top: 3px;
}
.accordionDetails {
     padding: 0 !important
}

.noauthendpoint {
     background-color: #EAEAEF;
     width:250px;
     padding: 18px,12px,18px;
     overflow: hidden;
}
.inputAdorment {
    margin-left: -13px;
    padding: 18px,12px,18px;

}
.deployedEndpoint{
     padding: 18px,12px,18px;
     overflow: hidden;

}
.noauthheader{
     margin-bottom: 10px;
}

.methodandcancel{
 position: absolute;
 left : 10x;
 margin-top: 10px;

}
.noauthdata {
     position: relative;
     margin-bottom: 10px;
}
.endpoint {
    max-width: 57%;
    overflow-x: hidden;
    text-overflow: clip;
    display: inline-flex;
}

.verb {
     position: relative;
     margin-top: 12px;
     left: 78px;
    top: -9px;
}
.divider {
     position: relative;
     margin-top: 2px;
     margin-bottom: 12px;

}
.method {
     position: absolute;
     left: 300px;
}
.cancel {
     position: relative;

     margin-top: -22px;
     left: 185px;
    top: -10px;
}
.noAuthPath {
     width: 500px;
}
.addPathButton {
     position: absolute;
     right: 0px;
     top : 47px;
}
.methodSelect {
     position: absolute;
     right: 140px;
     width: 180px;
     top: 30px
}

.product-expanded {
     rotate: 180deg;
     transition: all 0.5s ease-in-out;
}
.link {
     cursor: pointer;
}

.product-stack-card {
     &:last-child {
          border-bottom: 1px solid rgb(0, 46, 86);
     } 
}
.product-collapse {
     color: rgb(0, 46, 86);
      cursor: pointer;

}
.product-disabled {
     opacity: 0.5;
}

.MuiMenu-paper {
     option {
          padding: 15px;
          border-bottom: 1px solid #EAEAEF;
          &:hover {
               background-color: #EAEAEF;
               cursor: pointer;
          }
     }
}

.ag-cell>.ag-react-container:has(> *:nth-last-child(2):first-child) > *:first-child {
     display: none;
 }

.header-selector.MuiInputBase-root {
     border-radius: 2px;
 }
#environment-selector.MuiSelect-select {
     padding: 11px 37px 10px 12px !important;
     border: 1px solid #e0e1e9 !important;
     border-bottom: 1px solid #6a6e79 !important;
     border-radius: 0;
     margin-top: -2px;
     font-size: 12px;
 }
.header-selector .MuiOutlinedInput-notchedOutline {
     border: none !important;
}

.header-selector .MuiSelect-outlined.MuiSelect-outlined {
     padding-right: 45px;
}

li.selector-teams {
     padding-left: 30px;
}

.data-remover-button {
     &.Mui-disabled > * {
          opacity: 0.6;
     }
}

.consumer-secret-action-group {
	.data-remover-button {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          padding: 14px;
     }
}

.app-configuration {
     font-weight: 500 !important;
}

.ag-header-cell-text {
     color: #464B52;
     font-size: 14px;
     font-weight: 600;
   }
.ag-row {
     border: 1px solid #B7B9C3 !important;
     border-top-width: 0.5px !important;
     border-bottom-width: 0.5px !important;
     background-color: #fff  !important;
   }
.ag-theme-alpine .ag-row-odd {
     background-color: #fff  !important;
}

.resource-version-select > #versions {
     background-color: #fff;
     border-bottom-width: 2px;
     padding-top: 10px;
     padding-bottom: 10px;
     min-height: 24px;
}
.resource-version-select > fieldset {
     border-width: 0.5px;
    border-bottom-width: 2px;
}

.app-discovery-page .data-remover-button {
     border-color: transparent;
}

.unsubscribed-product-list {
     text-align: justify;
     margin-right: 45px;
}
.ag-theme-alpine .ag-icon {
     font-family: "agGridAlpine" !important;
 }


 .team-discovery-page {
     .data-remover-button {
          border-color: transparent;
     }
     .grid-title >p {
          color: #0E416C !important;
     }
 }