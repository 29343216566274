.proxy-card {
     .image-block {
          .proxy-image {
               width: 100%;
               padding: 15px;
               box-sizing: border-box;
          }
          .icon {
               color: #fff;
               font-size: 1.9em;
               margin-top: 13px;
               margin-left: 13px;
          }
     }
     .description {
          text-align: left;
          max-height: 87px;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 8px;
          margin-top: 0px;
     }
     .version {
          height: 25px;
          text-transform: capitalize;
          margin-right: 0;
          cursor: pointer;
     }
     .actions {
          display: flex;

          .left {
               margin-left: 0;
          }

          .right {
               margin-right: 0;
               .MuiSnackbar-root {
                    z-index: 100;
                    position: absolute;
                    width: 100%;
                    top: -88px;
               }
          }
     }
}
