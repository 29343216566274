.breadcrumbs {
     .breadcrumb {
          margin: 0;
          padding: 0;
          .breadcrumb-item {
               text-transform: capitalize;
               list-style-type: none;
               display: inline-block;
               margin-right: 10px;

               & > a {
                    color: #0c77be;
                    text-decoration: none;
                    text-transform: capitalize;
                    &:focus,
                    &:visited,
                    &:active,
                    &:hover {
                         text-decoration: none;
                    }
                    &:hover {
                         color: #015796;
                    }
               }
          }
     }
}
